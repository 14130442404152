<template>
  <b-container>
    <b-row>
      <Loader v-if="loading" />
      <template v-else>
        <b-col cols="12" class="mt-4">
          <b-card bg-variant="dark" text-variant="white" class="text-center">
            <b-img v-if="clientLogo" :src="clientLogo" fluid></b-img>
          </b-card>
        </b-col>
        <b-col
          v-for="(collection, index) in collections"
          :key="index"
          cols="12"
          md="6"
          class="mt-4 mx-auto"
        >
          <b-card bg-variant="dark" text-variant="white">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-card-img
                    :src="collection.image"
                    alt="Artwork"
                    class="rounded-0 img-client w-100"
                  ></b-card-img>
                  <br />
                  <b-card-title class="mt-3">{{ collection.title }}</b-card-title>
                  <div v-html="collection.description" class="collection-description"></div>
                  <br />
                  Price: ₳{{ collection.price | lovelace }}
                  <br />
                  Total to be minted: {{ collection.limit }}<br />
                  <b-button
                    class="mt-3"
                    variant="info"
                    :to="`/${client}/${collection.id}`"
                  >
                    More Info
                  </b-button>
                </b-col>
                <b-col md="12" v-if="!saleStarted(collection.startDate)">
                  <flip-countdown
                    :deadline="collection.startDate"
                  ></flip-countdown>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import FlipCountdown from "vue2-flip-countdown";
import Loader from "@/components/Loader";

export default {
  name: "Client",
  components: {
    FlipCountdown,
    Loader,
  },
  data() {
    return {
      loading: true,
      client: this.$route.params.client,
      collections: [],
    };
  },
  async created() {
    const collectionsRef = collection(db, "collections");
    const q = query(
      collectionsRef,
      where("client", "==", this.client),
      where("endDate", "==", null),
      orderBy("startDate", "desc"),
      limit(50)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size) {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.startDate = moment(data.startDate.toDate()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        results.push(data);
      });
      this.collections = results;
      this.loading = false;
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    clientLogo() {
      const collectionLogos = this.collections.find((x) => x.clientLogo);
      if (collectionLogos) {
        return collectionLogos.clientLogo;
      } else {
        return null;
      }
    },
  },
  methods: {
    saleStarted(startDate) {
      return moment().format() >= moment(startDate).format();
    },
  },
  filters: {
    lovelace(price) {
      return price / 1000000;
    },
  },
};
</script>

<style scoped>
.collection-description{
  text-align: justify;
}

.textorange {
  color: "orange";
}
</style>
